<template>
    <App title="Make A Claim">
        <template #top>
            <Alert ref="alert" class="alert-danger">
                {{ message }}
            </Alert>
        </template>
        <Block type="form">
            <template #header>
                <h3 class="block-title">Make A Claim</h3>
            </template>
            <div class="row">
                <div class="col-12">
                    <div class="input-group mb-3">
                        <input id="code" v-model="code" label="Code" placeholder="Order Code" class="form-control" :disabled="loading" />
                        <button class="btn btn-outline-secondary" type="button" @click="onCheck">Check</button>
                    </div>
                </div>
            </div>
            <div v-if="order" class="row">
                <div class="col-12">
                    <div class="row g-3">
                        <TextBlock label="Order Amount">
                            {{ $render.money(order.amount) }}
                        </TextBlock>
                    </div>
                    <div class="row g-3">
                        <TextBlock label="Order Created">
                            {{ $render.date(order.created_at) }}
                        </TextBlock>
                    </div>
                    <div class="row g-3">
                        <TextBlock label="Order Units">
                            {{ order.quantity }}
                        </TextBlock>
                    </div>
                    <div class="row g-3">
                        <TextBlock label="Order Type">
                            {{ order.type }}
                        </TextBlock>
                    </div>
                    <div class="row g-3">
                        <TextBlock label="Claims Left">
                            {{ order.claims_left }}
                        </TextBlock>
                    </div>
                    <div class="row g-3">
                        <TextBlock label="Claims taken">
                            {{ order.quantity - order.claims_left }}
                        </TextBlock>
                    </div>
                    <div class="row g-3">
                        <TextBlock label="User Fullname">
                            {{ order.user.fullname }}
                        </TextBlock>
                    </div>
                    <div class="row g-3">
                        <TextBlock label="User Phone">
                            {{ order.user.phone }}
                        </TextBlock>
                    </div>
                    <div class="row mt-3">
                        <div class="col text-center">
                            <button id="action" :disabled="loading || done || order.claims_left == 0 " class="btn btn-primary" @click="onSubmit">{{ label }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Block>
    </App>
</template>
<script lang="ts">
import Alert from '@/vue/components/Alert.vue'
import Block from '@/vue/components/Block.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
        Alert,
        TextBlock
    }
})
class AppLayout extends Vue {

    message = ''

    loading = false

    done = false

    code = ''

    order = null as any

    get label() {
        return this.done ? 'Claimed' : 'Claim'
    }

    onCheck() {
        this.loading = true
        this.$http
            .get(this.route('order.ajax.check', { code: this.code }))
            .then(({ data }) => {
                this.order = data.order
                this.done = false
            })
            .catch((e: AxiosError) => this.$error(e, this, true))
            .finally(() => this.loading = false)
    }

    onSubmit() {
        this.loading = true
        this.$http
            .post(this.route('claim.ajax.create'), { order: this.order?.id })
            .then(({ data }) => {
                this.done = true
                this.order = data.order
            })
            .catch((e: AxiosError) => this.$error(e, this, true))
            .finally(() => this.loading = false)
    }
}

export default toNative(AppLayout)
</script>
