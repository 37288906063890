<template>
    <nav id="sidebar" aria-label="Main Navigation">
        <!-- Side Header -->
        <div class="content-header">
            <!-- Logo -->
            <a class="fw-semibold text-dual">
                <span class="smini-visible">
                    <i class="fa fa-circle-notch text-primary"></i>
                    <!-- <img class="img-icon" src="@/images/svg/icon/icon.white.svg" alt="logo" /> -->
                </span>
                <span class="smini-hide fs-5 tracking-wider pushback">
                    {{ app_name }}
                    <!-- <img class="img-banner" src="@/images/svg/banner/banner.white.svg" alt="banner" /> -->
                </span>
            </a>
            <!-- END Logo -->

            <!-- Extra -->
            <div>
                <!-- Dark Mode -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
                <button type="button" class="btn btn-sm btn-alt-secondary" data-toggle="layout" data-action="dark_mode_toggle">
                    <i class="far fa-moon"></i>
                </button>
                <!-- END Dark Mode -->

                <!-- Close Sidebar, Visible only on mobile screens -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
                <a class="d-lg-none btn btn-sm btn-alt-secondary ms-1" data-toggle="layout" data-action="sidebar_close" href="javascript:void(0)">
                    <i class="fa fa-fw fa-times"></i>
                </a>
                <!-- END Close Sidebar -->
            </div>
            <!-- END Extra -->
        </div>
        <!-- END Side Header -->



        <!-- Sidebar Scrolling -->
        <div class="js-sidebar-scroll">
            <!-- Side Navigation -->
            <div class="content-side">
                <MenuList :menu="menu" />
            </div>
            <!-- END Side Navigation -->
        </div>
        <!-- END Sidebar Scrolling -->
    </nav>
</template>

<script lang="ts">
import { MenuItem } from '@/vue/components/Menu'
import MenuList from '@/vue/components/Menu.vue'
import { Component, toNative, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        MenuList
    }
})
class SidebarLeft extends Vue {
    get app_name() {
        return import.meta.env.VITE_APP_NAME
    }

    menu: MenuItem[] = [
        {
            label: 'Dashboard',
            name: 'dashboard.page',
            icon: 'fas fa-gauge',
        },
        {
            label: 'Make A Claim',
            name: 'claim.page.create',
            icon: 'fas fa-vault',
        },
        {
            label: 'Inventory',
            name: '',
            heading: true
        },
        {
            label: 'Orders',
            name: 'order.page.group',
            icon: 'fas fa-sack-dollar',
        },
        {
            label: 'Claims',
            name: 'claim.page.group',
            icon: 'fas fa-cash-register',
        },
        {
            label: 'Transactions',
            name: 'transaction.page.group',
            icon: 'fas fa-sack-dollar',
        },
        {
            label: 'Users',
            name: 'user.page.group',
            icon: 'fas fa-user',
        },
        {
            label: 'Admins',
            name: 'admin.page.group',
            icon: 'fas fa-user-ninja',
            submenu: [
                {
                    label: 'Admins',
                    name: 'admin.page.group',
                },
                {
                    label: 'Create Admin',
                    name: 'admin.page.create',
                },
            ],
        },
        {
            label: 'Profile',
            name: '',
            heading: true
        },
        {
            label: 'Profile',
            name: 'profile.page.single',
            icon: 'far fa-user',
            submenu: [
                {
                    label: 'Profile',
                    name: 'profile.page.single',
                },
                {
                    label: 'Reset Password',
                    name: 'profile.page.password.reset',
                },
            ],
        },
        {
            label: 'Logout',
            name: 'auth.logout',
            icon: 'fas fa-sign-out-alt',
            external: true,
        },
    ]

    created() {
        if (["development", "staging", "local"].includes(import.meta.env.VITE_APP_ENV)) {
            this.menu.push(
                {
                    label: 'Development Tools',
                    name: '',
                    heading: true,
                },
                {
                    label: 'Telescope',
                    name: 'telescope',
                    icon: 'fa-solid fa-screwdriver-wrench',
                    external: true,
                },
                {
                    label: 'Pulse',
                    name: 'pulse',
                    icon: 'fa-solid fa-screwdriver-wrench',
                    external: true,
                },
                {
                    label: 'Horizon',
                    name: 'horizon.index',
                    icon: 'fa-solid fa-screwdriver-wrench',
                    external: true,
                },
                {
                    label: 'Mailbook',
                    name: 'mailbook.dashboard',
                    icon: 'fa-solid fa-screwdriver-wrench',
                    external: true,
                },
                // {
                //     label: 'Tinker',
                //     name: 'tinker',
                //     icon: 'fa-solid fa-screwdriver-wrench',
                //     external: true,
                // },
                {
                    label: 'Docs',
                    name: 'scribe',
                    icon: 'fa-solid fa-screwdriver-wrench',
                    external: true,
                },
            )
        }
    }
}

export default toNative(SidebarLeft)
</script>

<style lang="scss">
.img-banner,
.img-icon {
    width: auto;
    height: 20px;
}

.nav-main-link,
.nav-main-link-icon {
    color: #717e8c !important;

    &:hover,
    &.nav-main-link-icon:hover {
        color: white !important;
    }
}

@import "~simplebar/dist/simplebar.css";
</style>
