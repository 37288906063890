<template>
    <App title="Admins">
        <Block>
            <template #header>
                <h3 class="block-title">Admins</h3>
            </template>
            <Datatable :options="options" />
        </Block>
    </App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Options, Vue } from 'vue-decorator'

@Options({
    components: {
        App,
        Block,
        Datatable,
    },
})
export default class Group extends Vue {
    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push({
            extend: "create",
            attr: {
                href: this.route("admin.page.create"),
            },
        })
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })

        columns.push({
            data: 'fullname',
            title: 'Name',
        })
        columns.push({
            data: 'email',
            title: 'email',
            orderable: true,
            name: 'title',
        })
        columns.push({
            data: 'phone',
            title: 'Phone',
            orderable: true,
            name: 'phone'
        })

        columns.push({
            data: 'created_at',
            title: 'Registered',
            render: CellRender.date(),
            orderable: true,
            name: 'created_at',
        })

        columns.push({
            title: 'Actions',
            data: 'url',
            render: CellRender.actions(),
        })

        columns.push({
            title: 'Admin',
            data: 'id',
            render: CellRender.admin(),
        })
        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('admin.ajax.table'),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
        }
    }
}
</script>
