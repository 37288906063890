<template>
    <Auth>
        <template #heading>
            <h1 class="fw-bold mb-2">Recover Password</h1>
            <p class="fw-medium text-muted">Input your email and we will send you a password reset link.</p>
        </template>
        <Alert ref="alert" class="alert-danger">
            {{ message }}
        </Alert>
        <Form ref="form" @submit="onSubmit">
            <InputField id="email" v-model="email" name="email" rules="required|email" label="Email" type="email" placeholder="Email" />
            <div class="d-flex justify-content-between align-items-center mb-4">
                <div>
                    <a :href="route('login')" class="text-muted fs-sm fw-medium d-block d-lg-inline-block mb-1">Log in</a>
                </div>
                <div>
                    <Button id="action" type="submit" :label="labels" :loading="loading" :done="done" class="btn-lg btn-alt-primary" />
                </div>
            </div>
        </Form>
    </Auth>
</template>

<script lang="ts">
import { error } from '@/ts/app'
import Alert from '@/vue/components/Alert.vue'
import { ButtonLabel } from '@/vue/components/Button'
import Button from '@/vue/components/Button.vue'
import Form from '@/vue/components/Form.vue'
import InputField from '@/vue/components/InputField.vue'
import Auth from '@/vue/templates/Auth.vue'
import { AxiosError } from 'axios'
import Swal from 'sweetalert2'
import { Component, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

@Component({
    components: {
        Auth,
        Alert,
        InputField,
        Form,
        Button,
    },
})
class RecoverPassword extends Vue {
    message = ''

    email = ''

    loading = false
    done = false

    @Ref('form')
    readonly form!: InstanceType<typeof Form>

    @Ref('alert')
    readonly alert!: InstanceType<typeof Alert>

    @Watch('message')
    onMessageChanged(value: string) {
        const alert: HTMLDivElement = this.alert.$el
        if (value.length > 0) {
            alert.classList.add('show')
        } else {
            alert.classList.remove('show')
        }
    }

    get labels(): ButtonLabel {
        return {
            normal: 'Recover',
            loading: 'Recovering..... Password',
            done: 'Recovered Password',
        } as ButtonLabel
    }

    async onSubmit(payload) {
        this.loading = true
        this.message = ''
        this.$http
            .post(this.route('auth.ajax.password.recover'), payload)
            .then(() => {
                this.done = true
                Swal.fire({
                    title: 'Success!',
                    text: 'Password reset link has been sent to your email.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                })
            })
            .catch((e: AxiosError) => error(e, this))
            .finally(() => (this.loading = false))
    }
}

export default toNative(RecoverPassword)
</script>
