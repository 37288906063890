<template>
    <App title="Claim">
        <div class="row">
            <div class="col">
                <Block>
                    <template #header>
                        <h3 class="block-title">Claim</h3>
                    </template>
                    <Datatable :options="options" />
                </Block>
            </div>
        </div>
    </App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Config } from 'datatables.net-bs5'
import { Component, toNative, Vue } from 'vue-facing-decorator'

@Component({
    components: {
        App,
        Block,
        Datatable,
    },
})
class Group extends Vue {
    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })
        columns.push({
            data: 'user.fullname',
            title: 'Name',
        })

        columns.push({
            data: 'order.code',
            title: 'Code',
        })

        columns.push({
            name: 'created_at',
            data: 'created_at',
            title: 'Created',
            render: CellRender.date(),
            orderable: true
        })

        return columns
    }

    get options(): Config {
        return {
            ajax: {
                url: this.route('claim.ajax.table'),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[3, 'desc']]
        }
    }
}

export default toNative(Group)
</script>
