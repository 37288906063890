<template>
    <App title="Transactions">
        <Block>
            <template #header>
                <h3 class="block-title">Transactions</h3>
            </template>
            <Datatable :options="options" />
        </Block>
    </App>
</template>

<script lang="ts">
import { Status, TransactionTypeLabel, Type } from '@/models/Transaction'
import Block from '@/vue/components/Block.vue'
import { CellRender } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Options, Vue } from 'vue-decorator'

@Options({
    components: {
        App,
        Block,
        Datatable,
    },
})
export default class Group extends Vue {
    get buttons() {
        let buttons: any[] = []
        buttons.push('refresh')
        buttons.push('trashed')

        return buttons
    }

    get columns() {
        let columns: any[] = []
        columns.push({
            data: 'id',
            orderable: true,
            name: 'id',
            title: 'Id',
        })

        columns.push({
            data: 'user.fullname',
            title: 'Name',
        })

        columns.push({
            data: 'type',
            title: 'Type',
            render: (data) => {
                let style = ''
                switch (data) {
                    case Type.PENALTY:
                    case Type.CHARGE:
                    case Type.DEBIT:
                    case Type.AUTO:
                        style = 'text-danger'
                        break
                    case Type.CREDIT:
                        style = 'text-success'
                        break
                }
                return `<span class="${style} text-uppercase">${TransactionTypeLabel(data)}</span>`
            }
        })

        columns.push({
            data: 'status',
            title: 'Status',
            render: (data) => {
                let style = ''
                switch (data) {
                    case Status.FAILED:
                        style = 'text-danger'
                        break
                    case Status.SUCCESSFUL:
                        style = 'text-success'
                        break
                    case Status.PENDING:
                        style = 'text-warning'
                        break
                }
                return `<span class="${style} text-uppercase">${data}</span>`
            }
        })

        columns.push({
            data: 'amount',
            title: 'Amount',
            render: CellRender.money()
        })

        columns.push({
            data: 'created_at',
            name: 'created_at',
            title: 'Created',
            orderable: true,
            render: CellRender.date()
        })

        columns.push({
            data: 'verified_at',
            title: 'Verified',
            orderable: true,
            render: CellRender.date()
        })



        return columns
    }

    get options() {
        return {
            ajax: {
                url: this.route('transaction.ajax.table'),
                method: 'POST',
            },
            buttons: this.buttons,
            columns: this.columns,
            order: [[5, 'desc']]
        }
    }
}
</script>
