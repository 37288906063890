<template>
    <Field v-slot="{ field, errors }" :name="name" :rules="rules" :label="label">
        <Check v-bind="{ ...field, ...$attrs }" :label="label">
            <template #error>
                <Errors :errors="errors" />
            </template>
        </Check>
    </Field>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'
import Check from './Check.vue'
import Errors from './Errors.vue'
import Field from './Field.vue'

@Component({
    components: {
        Errors,
        Field,
        Check,
    },
    inheritAttrs: false,
    emits: ['update:modelValue'],
})
class CheckField extends Vue {

    @Prop({ type: String })
    readonly name!: string

    @Prop({ type: [String, Object] })
    readonly rules!: string | object

    @Prop({ type: String })
    readonly label!: string
}

export default toNative(CheckField)
</script>

<style></style>
