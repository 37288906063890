<template>
	<App title="Dashboard">
		<div class="content">
			<h1 class="content-heading">Statistics</h1>
			<div class="row">
				<CounterAsync title="Users" icon="fas fa-user" :url="url({ type: 'count', data: 'users' })" />
			</div>
			<div class="row">
				<CounterAsync title="Transactions" icon="fas fa-landmark" :url="url({ type: 'count', data: 'transactions' })" />
				<CounterAsync title="Orders" icon="fas fa-cart-flatbed" :url="url({ type: 'count', data: 'orders' })" />
				<CounterAsync title="Claims" icon="fas fa-money-check" :url="url({ type: 'count', data: 'claims' })" />
			</div>
			<h1 class="content-heading">Finance</h1>
			<div class="row">
				<CounterAsync title="Total Revenue" icon="fas fa-coins" :url="url({ type: 'money', data: 'total' })" type="money" />
			</div>
			<div class="row">
				<CounterAsync title="Today" icon="fas fa-calendar-day" :url="url({ type: 'money', data: 'day' })" type="money" />
				<CounterAsync title="The Last 7 Days" icon="fas fa-calendar-week" :url="url({ type: 'money', data: 'week' })" type="money" />
				<CounterAsync title="The Last 30 Days" icon="fas fa-calendar-days" :url="url({ type: 'money', data: 'month' })" type="money" />
			</div>
		</div>
	</App>
</template>
<script lang="ts">
import Chart from '@/vue/components/Chart.vue'
import CounterAsync from '@/vue/components/CounterAsync.vue'
import App from '@/vue/templates/App.vue'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Chart,
		CounterAsync,
	},
})
class Dashboard extends Vue {
	url(query) {
		return this.route('dashboard.ajax.counters', query)
	}
}

export default toNative(Dashboard)
</script>
