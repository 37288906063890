import Account from './Account'
import Address from './Address'
import Card from './Card'
import Easy from './Easy'
import Employment from './Employment'
import Kin from './Kin'
import Model from './Model'
import RNPL from './RNPL'
import Transaction from './Transaction'
import Vault from './Vault'
import Wallet from './Wallet'
import ZERO from './ZERO'

export default interface User extends Model {
	tag?: string
	readonly fullname: string
	firstname: string
	lastname: string
	email: string
	phone: string
	gender: Gender
	birthday_at: string
	bvn?: string
	gid?: string
	gid_type?: GID

	isEmailVerified: boolean
	isBVNVerified: boolean
	isGIDVerified: boolean
	isCreditVerified: boolean
	isProfileVerified: boolean
	isEmploymentVerified: boolean

	address?: Address
	kin?: Kin
	employment?: Employment

	transactions: Transaction[]

	vaults?: Vault[]
	easies?: Easy[]

	rnpl: RNPL
	zero: ZERO

	account?: Account
	accounts: Account[]

	card?: Card
	cards: Card[]

	wallet?: Wallet
}

export enum Gender {
	MALE = 'male',
	FEMALE = 'female',
}

export enum GID {
	NIN = 'nin',
	DLN = 'dln',
	VIN = 'vin',
	IPN = 'ipn',
}

export const GIDTitle = (gid?: GID) => {
	switch (gid) {
		case GID.NIN:
			return 'National Identification Number'
		case GID.DLN:
			return 'Driver License Number'
		case GID.VIN:
			return 'Voter Identification Number'
		case GID.IPN:
			return 'International Passport Number'
		default:
			return ''
	}
}
